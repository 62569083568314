<!-- @format -->

<template>
	<div>
		<ProcessReportHeaders
			:customer="reportCustomer"
			:fileName="fileName"
			:reportDocumentDate="reportDocumentDate"
			:reportDocumentStatus="reportDocumentStatus"
		/>
		<div>
			<ReportButtons
				:reportCustomerEmail="reportCustomerEmail"
				:openPDF="openPDF"
				:downloadPDF="downloadPDF"
				:printPDF="printPDF"
				:emailPDF="emailPDF"
				:hasValidEmail="hasValidEmail"
				:reportDocumentType="reportDocumentType"
				:sendDocumentBy="sendDocumentBy"
				:key="keyReportButtons"
			/>
		</div>
		<div v-if="showSaveEmail">
			<ProcessReportEmail
				:reportHeaders="props.reportHeaders"
				:isToSave="isToSaveValue"
				:closeModal="closeSaveEmail"
			/>
		</div>
		<div v-if="showProcessReportEmail">
			<ProcessReportEmail
				:reportHeaders="props.reportHeaders"
				:isToSave="isToSaveFalseValue"
				:closeModal="closeSaveEmail"
			/>
		</div>
		<div>
			<ProcessReportUnlockDocument />
		</div>
	</div>
</template>

<script>
import ReportButtons from '@/_srcv2/components/_shared/report-buttons/ReportButtons.vue'
import ProcessReportHeaders from '@/_srcv2/pages/_reports/create-report/ProcessReportHeaders.vue'
import ProcessReportUnlockDocument from './ProcessReportUnlockDocument.vue'
import ProcessReportEmail from '@/_srcv2/pages/_reports/create-report/ProcessReportEmail.vue'
import useDocumentDefinition from '@/_srcv2/pages/_reports/create-report/useDocumentDefinition.js'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import { onMounted, ref } from '@vue/composition-api'

export default {
	name: 'ProcessReport',
	components: {
		ReportButtons,
		ProcessReportHeaders,
		ProcessReportUnlockDocument,
		ProcessReportEmail,
	},
	props: {
		reportHeaders: Object,
	},
	setup(props) {
		const isToSaveValue = ref()
		const isToSaveFalseValue = ref(false)

		const {
			reportDocumentType,
			reportProcessType,
			reportCustomer,
			reportCustomerEmail,
			reportDocumentDate,
			reportDocumentStatus,
			sendDocumentBy,
			hasValidEmail,
			keyReportButtons,
			showProcessReportEmail,
		} = useProcessReportStore()
		console.log('reportCustomer', reportCustomer.value)
		// todo -------------------------------------------------------------
		const rh = props.reportHeaders
		const showSaveEmail = ref()
		const closeSaveEmail = () => {
			showSaveEmail.value = false
			showProcessReportEmail.value = false
		}
		// todo ----------------------------------------------------------------------------
		const { getDocumentDefinition } = useDocumentDefinition()
		const getData = () => {
			return new Promise((resolve, reject) => {
				const dd = getDocumentDefinition(reportDocumentType.value)
				if (dd !== undefined || null) {
					resolve(dd)
				} else {
					const errorObject = {
						msg: "Data couldn't be fetched from store",
					}
					reject(errorObject)
				}
			})
		}
		// todo -------------------------------------------------------------------------
		const createReport = (dd) => {
			return new Promise((resolve, reject) => {
				if (dd !== undefined || null) {
					const pdfMake = require('pdfmake/build/pdfmake.js')
					const pdfFonts = require('pdfmake/build/vfs_fonts.js')
					pdfMake.vfs = pdfFonts.pdfMake.vfs
					const pdf = pdfMake.createPdf(dd)
					resolve(pdf)
				} else {
					const errorObject = {
						msg: "The report couldn't be created",
					}
					reject(errorObject)
				}
			})
		}
		// todo --------------------------------------------------------------
		// ** Get file name
		const fileName = String(rh.reportType + '-' + rh.reportNumber)
			.trim()
			.replaceAll(' ', '')
			.toLowerCase()
		// todo --------------------------------------------------------------
		const openPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.open())
		}
		// todo --------------------------------------------------------------
		const downloadPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.download(`${fileName}.pdf`))
		}
		// todo --------------------------------------------------------------
		const printPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.print())
		}
		// todo --------------------------------------------------------------
		const emailPDF = () => {
			keyReportButtons.value += 1
			showProcessReportEmail.value = true
		}
		// todo -------------------------------------------------------------------------
		onMounted(() => {
			setTimeout(() => {
				console.log('reportDocumentType', reportDocumentType.value)
				isToSaveValue.value = reportDocumentType.value === 'sale-invoice'
				showSaveEmail.value =
					reportDocumentType.value === 'sale-invoice' &&
					reportProcessType.value === 'add'
						? true
						: false
				reportDocumentStatus.value = 'Processing'
			}, 500)
		})
		return {
			props,
			openPDF,
			downloadPDF,
			printPDF,
			emailPDF,
			fileName,
			reportCustomer,
			reportCustomerEmail,
			reportDocumentDate,
			reportDocumentStatus,
			reportDocumentType,
			sendDocumentBy,
			hasValidEmail,
			showProcessReportEmail,
			keyReportButtons,
			showSaveEmail,
			closeSaveEmail,
			isToSaveValue,
			isToSaveFalseValue,
		}
	},
}
</script>

<style scoped></style>
