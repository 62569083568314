<!-- @format -->

<template>
	<!-- The Modal -->
	<div id="myModal" class="modal">
		<!-- Modal content -->
		<div class="modal-content">
			<div class="modal-header">
				<slot name="header">{{ $props.header }}</slot>
			</div>
			<div>
				close
				<b-button
					type="button"
					class="is-danger margin-top-20"
					style="margin-left: 50px; width: 250px"
					@click="$props.closePopup"
					aria-label="Close modal"
				>
					X Close X
				</b-button>
				<b-button
					v-if="showSaveToDropbox"
					type="button"
					class="is-success margin-top-20"
					style="margin-left: 50px; width: 250px"
					@click="onSaveToDropbox"
					aria-label="Close modal"
				>
					Save to Dropbox X
				</b-button>
			</div>
			<div class="modal-body">
				<slot name="body"></slot>
			</div>
			<div class="modal-footer">
				<slot name="footer">{{ $props.footer }}</slot>
			</div>
		</div>
	</div>
</template>

<script>
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import { onMounted, ref } from '@vue/composition-api'
export default {
	name: 'EmailPopup',
	props: {
		closePopup: {
			type: Function,
		},
		header: {
			type: String,
			default: '',
		},
		footer: {
			type: String,
			default: '',
		},
		// disableCloseButton: {
		// 	type: Boolean,
		// 	default: false,
		// },
		sendToDropbox: {
			type: Function,
			default: () => {},
		},
		setup() {
			const formName = ref('')
			return {
				formName,
			}
		},
	},
	setup(props) {
		const { reportDocumentType, reportProcessType } = useProcessReportStore()
		const showSaveToDropbox = ref()
		onMounted(() => {
			showSaveToDropbox.value =
				reportDocumentType.value === 'sale-invoice' &&
				reportProcessType.value === 'view'
			console.log('showSaveToDropbox.value', showSaveToDropbox.value)
		})
		// todo --------------------------------------------------------------------
		const onSaveToDropbox = () => {
			props.sendToDropbox()
		}
		return {
			showSaveToDropbox,
			onSaveToDropbox,
		}
	},
}
</script>
<style scoped>
/* The Modal (background) */
.modal {
	display: block; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 100000000000000; /* Sit on top */
	padding-top: 0; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	max-height: 100%;
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
	position: relative;
	background-color: rgb(76, 92, 122);
	margin-left: auto;
	margin-top: 5%;
	margin-bottom: 5%;
	max-height: 86%;
	border: 1px solid #888;
	max-width: 1050px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-animation-name: animatetop;
	-webkit-animation-duration: 0.4s;
	animation-name: animatetop;
	animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}

@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}

/* The Close Button */
.close {
	color: white;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.modal-header {
	background-color: #00d1b2;
	color: white;
	text-align: center;
	font-weight: 900;
	font-size: larger;
	height: 60px;
	padding-top: 17px;
}

.modal-body {
	padding: 2px 16px;
}

.modal-footer {
	margin-top: 30px;
	background-color: #00d1b2;
	color: white;
	text-align: center;
	font-weight: 900;
	font-size: larger;
	height: 60px;
	padding-top: 17px;
}
</style>
